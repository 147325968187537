<!--认证信息--添加数据-->
<template>
  <div id="addData" ref="addData">
    <a-modal
      :title="$t('information.a58')"
      centered
      :width="600"
      :visible="visible"
      :destroyOnClose="true"
      :getContainer="() => this.$refs.addData"
      :ok-button-props="okbutton"
      :cancel-button-props="cancelbutton"
      @ok="handleOk"
      @cancel="shut"
      v-drag
    >
      <div v-loading="loading" :element-loading-text="$t('information.a1')">
        <h3>HHllo</h3>
        <a-form-model
          layout="inline"
          :model="form"
          :rules="rules"
          ref="ruleForm"
        >
          <a-row type="flex" align="middle" style="margin-bottom: 20px">
            <a-col :span="3"> {{ $t("information.a9") }} </a-col>
            <a-col :span="8">
              <a-form-model-item style="margin: 0; height: 40px" prop="cardNo">
                <a-input
                  allow-clear
                  v-model="form.cardNo"
                  :placeholder="$t('information.a10')"
                  @input="handleInput($event, 1)"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="2"></a-col>
            <a-col :span="3"> {{ $t("information.a11") }} </a-col>
            <a-col :span="8">
              <a-form-model-item
                style="margin: 0; height: 40px"
                prop="cardFaceNo"
              >
                <a-input
                  allow-clear
                  v-model="form.cardFaceNo"
                  :placeholder="$t('information.a12')"
                  @input="handleInput($event, 2)"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
        <a-row type="flex" align="middle" style="margin-bottom: 15px">
          <a-col :span="3"> {{ $t("information.a59") }} </a-col>
          <a-col :span="8">
            <a-select v-model="temporaryCard" style="width: 100%">
              <a-select-option :value="0">
                {{ $t("information.a18") }}
              </a-select-option>
              <a-select-option :value="1">
                {{ $t("information.a19") }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :span="2"></a-col>
          <a-col :span="3"> {{ $t("information.a60") }} </a-col>
          <a-col :span="8">
            <a-button type="primary" style="width: 120px" @click="alteration">
              {{ $t("information.a50") }}
            </a-button>
          </a-col>
        </a-row>
        <div class="card">
          <a-tree
            showLine
            default-expand-all
            :tree-data="cardList"
            :replace-fields="replaceFields"
            @select="nodeSelect"
            v-if="visible"
          />
        </div>
        <div>
          <span>{{ $t("information.a47") }}</span>
          <a-textarea
            v-model="description"
            allow-clear
            :auto-size="{ minRows: 1, maxRows: 3 }"
          />
        </div>
      </div>
      <my-cardAffiliationCompile
        :visible="cardcompile"
        :data="data"
        @shut="handleCancel"
        :siteId="siteId"
      />
    </a-modal>
  </div>
</template>


<script>
import cardAffiliationCompile from "./cardAffiliationCompile";
import { addData } from "../../../api/info";

export default {
  props: {
    visible: {
      type: Boolean,
    },
    data: {
      type: Array,
    },
    siteId: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      cardcompile: false,
      replaceFields: { title: "name", children: "children", key: "id" },
      form: {
        cardNo: "",
        cardFaceNo: "",
      },
      temporaryCard: 0,
      description: "",
      cardList: [],
      credentialGrId: "",
      okbutton: { props: { disabled: false } },
      cancelbutton: { props: { disabled: false } },
      rules: {
        cardNo: [
          {
            required: true,
            message: this.$t("information.a61"),
            trigger: "change",
          },
        ],
        cardFaceNo: [
          {
            required: true,
            message: this.$t("information.a62"),
            trigger: "change",
          },
        ],
      },
    };
  },
  watch: {
    // 卡所属列表操作
    data: {
      handler: function (newValue, oldValue) {
        if (newValue.length > 0) {
          this.cardList = newValue[0].children;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    // 添加卡片所属关闭函数
    shut() {
      this.$emit("shut", false);
      this.form.cardFaceNo = "";
      this.form.cardNo = "";
      this.description = "";
      this.temporaryCard = 0;
    },
    // 添加确定按钮
    handleOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.okbutton.props.disabled = true;
          this.cancelbutton.props.disabled = true;
          let data = {
            cardFaceNo: this.form.cardFaceNo,
            cardNo: this.form.cardNo,
            description: this.description,
            temporaryCard: this.temporaryCard,
            siteId: this.siteId,
            credentialGrId: this.credentialGrId,
          };
          // console.log(data);
          addData(data)
            .then((res) => {
              // console.log(res);
              if (res.errorCode == "00") {
                this.$message.success(res.msg);
                this.form.cardFaceNo = "";
                this.form.cardNo = "";
                this.description = "";
                this.temporaryCard = 0;
                this.shut();
                this.loading = false;
                this.okbutton.props.disabled = false;
                this.cancelbutton.props.disabled = false;
                this.$parent.inquire();
              } else if (res.errorCode !== "00") {
                this.$message.error(res.msg);
                this.loading = false;
                this.okbutton.props.disabled = false;
                this.cancelbutton.props.disabled = false;
              }
            })
            .catch((err) => {
              this.loading = false;
              this.okbutton.props.disabled = false;
              this.cancelbutton.props.disabled = false;
              console.log(err);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 关闭卡所属编辑窗口
    handleCancel(e) {
      this.cardcompile = e;
    },
    // 开启卡所属编辑窗口
    alteration() {
      this.cardcompile = true;
    },
    // 卡所属选择回调
    nodeSelect(selectedKeys, info) {
      this.credentialGrId = selectedKeys[0];
    },
    // 输入框文本格式
    handleInput(e, value) {
      let amend = e.target.value
        .replace(/^0{1,}/g, "")
        .replace(
          /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g,
          ""
        )
        .replace(/[^\x00-\xff]/g, "")
        .replace(/[, ]/g, "");
      switch (value) {
        case 1:
          return (this.form.cardNo = amend);
          break;
        case 2:
          return (this.form.cardFaceNo = amend);
          break;
        default:
          return true;
          break;
      }
    },
  },
  components: {
    "my-cardAffiliationCompile": cardAffiliationCompile,
  },
};
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
.card {
  width: 100%;
  height: 150px;
  border: 1px solid rgba(12, 12, 12, 0.2);
  overflow: auto;
  margin-bottom: 15px;
}
</style>