<!--认证信息--卡片数据编辑-->
<template>
  <div id="compileData" ref="compileData">
    <a-modal
      :title="$t('information.a64')"
      centered
      :width="600"
      :visible="visible"
      :getContainer="() => this.$refs.compileData"
      :destroyOnClose="true"
      @cancel="Cancel"
      v-drag
    >
      <template slot="footer">
        <a-button key="back" @click="Cancel">
          {{ $t("information.a51") }}
        </a-button>
        <a-button key="submit" type="primary" @click="handleOk">
          {{ $t("information.a65") }}
        </a-button>
      </template>
      <div class="nihao" v-loading="loading" element-loading-text="加载中....">
        <a-form-model
          layout="inline"
          :model="form"
          :rules="rules"
          ref="ruleForm"
        >
          <a-row type="flex" align="middle" style="margin-bottom: 20px">
            <a-col :span="3"> {{ $t("information.a9") }} </a-col>
            <a-col :span="8">
              {{ cardNo }}
            </a-col>
            <a-col :span="2"></a-col>
            <a-col :span="3"> {{ $t("information.a11") }} </a-col>
            <a-col :span="8">
              <a-form-model-item
                style="margin: 0; height: 40px"
                prop="cardFaceNo"
              >
                <a-input
                  allow-clear
                  v-model="form.cardFaceNo"
                  :placeholder="$t('information.a12')"
                  @input="handleInput($event, 2)"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
        <a-row type="flex" align="middle" style="margin-bottom: 15px">
          <a-col :span="3"> {{ $t("information.a59") }} </a-col>
          <a-col :span="8">
            <a-select
              v-model="temporaryCard"
              :disabled="disabled"
              style="width: 100%"
            >
              <a-select-option :value="0">
                {{ $t("information.a18") }}
              </a-select-option>
              <a-select-option :value="1">
                {{ $t("information.a19") }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :span="2"></a-col>
          <a-col :span="3"> {{ $t("information.a60") }} </a-col>
          <a-col :span="8">
            <a-button type="primary" style="width: 120px" @click="alteration">
              {{ $t("information.a50") }}
            </a-button>
          </a-col>
        </a-row>
        <div class="card">
          <a-tree
            showLine
            default-expand-all
            :tree-data="cardList"
            :replace-fields="replaceFields"
            :default-selected-keys="node"
            @select="nodeSelect"
          />
        </div>
        <div>
          <span>{{ $t("information.a47") }}</span>
          <a-textarea
            v-model="description"
            allow-clear
            :auto-size="{ minRows: 1, maxRows: 3 }"
          />
        </div>
      </div>
      <my-cardAffiliationCompile
        :visible="cardcompile"
        :data="data"
        @shut="handleCancel"
        :siteId="siteId"
      />
    </a-modal>
  </div>
</template>

 
<script>
import cardAffiliationCompile from "./cardAffiliationCompile";
import { onlyCompile } from "../../../api/info";

export default {
  props: {
    visible: {
      type: Boolean,
    },
    data: {
      type: Array,
    },
    cardData: {
      tyep: Object,
    },
    siteId: {
      type: String,
    },
  },
  data() {
    return {
      cardcompile: false,
      replaceFields: { title: "name", children: "children", key: "id" },
      cardIds: [],
      cardId: "",
      cardNo: "",
      form: {
        cardFaceNo: "",
      },
      cardFaceNo: "",
      disabled: "",
      cardList: [],
      temporaryCard: 0,
      credentialGrId: "",
      description: "",
      node: [],
      loading: false,
      rules: {
        cardFaceNo: [
          {
            required: true,
            message: this.$t("information.a62"),
            trigger: "change",
          },
        ],
      },
    };
  },
  watch: {
    // 根据单一和批量改变页面
    visible: {
      handler: function (newValue, oldValue) {
        if (newValue) {
          const value = this.cardData;
          this.cardId = value.cardId;
          this.cardNo = value.cardNo;
          this.form.cardFaceNo = value.cardFaceNo;
          this.credentialGrId = value.credentialGrId.toString();
          this.node = [value.credentialGrId.toString()];
          this.temporaryCard = value.temporaryCard;
          this.description = value.description;
        }
      },
      deep: true,
      immediate: true,
    },
    // 卡所属列表操作
    data: {
      handler: function (newValue, oldValue) {
        if (newValue.length > 0) {
          this.cardList = newValue[0].children;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    // 卡片所属编辑关闭函数
    Cancel() {
      this.$emit("shut", false);
    },
    // 编辑窗口确定按钮
    handleOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let data = {
            cardFaceNo: this.form.cardFaceNo,
            cardId: this.cardId,
            siteId: this.siteId,
            temporaryCard: this.temporaryCard,
            description: this.description,
            credentialGrId: this.credentialGrId,
          };
          onlyCompile(data)
            .then((res) => {
              console.log(res);
              this.loading = false;
              if (res.errorCode == "00") {
                const { data } = res;
                this.$emit("Update", data);
                this.$message.success(res.msg);
                this.Cancel();
              } else if (res.errorCode !== "00") {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              // console.log(err);
              this.loading = false;
            });
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    // 关闭卡所属编辑窗口
    handleCancel(e) {
      this.cardcompile = e;
    },
    // 开启卡所属编辑窗口
    alteration() {
      this.cardcompile = true;
    },
    // 卡所属选择回调
    nodeSelect(selectedKeys, info) {
      this.credentialGrId = selectedKeys[0];
    },
    // 输入框文本格式
    handleInput(e, value) {
      let amend = e.target.value
        .replace(/^0{1,}/g, "")
        .replace(
          /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g,
          ""
        )
        .replace(/[^\x00-\xff]/g, "")
        .replace(/[, ]/g, "");
      switch (value) {
        case 1:
          return (this.form.cardNo = amend);
          break;
        case 2:
          return (this.form.cardFaceNo = amend);
          break;
        default:
          return true;
          break;
      }
    },
  },
  components: {
    "my-cardAffiliationCompile": cardAffiliationCompile,
  },
};
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
.card {
  width: 100%;
  height: 150px;
  border: 1px solid rgba(12, 12, 12, 0.2);
  overflow: auto;
  margin-bottom: 15px;
}
.nihao {
  width: 100%;
  height: 100%;
}
</style>