<!--卡片信息-->
<template>
  <div
    id="information"
    v-loading="loading"
    :element-loading-text="$t('information.a1')"
  >
    <!-- 查询栏 -->
    <div class="header">
      <my-headertitle>
        {{ $t("information.a2")}}
        <template v-slot:name>
          <span style="margin-right: 15px">{{ $t("information.a4") }}</span>
          <a-cascader
            style="width: 250px; margin-right: 150px"
            change-on-select
            :options="options"
            :field-names="{ label: 'name', value: 'id', children: 'children' }"
            :allowClear="false"
            v-model="select"
          />
        </template>
      </my-headertitle>
      <div class="queryBar">
        <a-row :gutter="16" type="flex" align="bottom">
          <a-col :span="2">
            <a-input
              :maxLength="30"
              allow-clear
              v-model="name"
              :placeholder="$t('information.a5')"
              :title="$t('information.a5')"
              style="width: 100%"
              v-InputText
            />
          </a-col>
          <a-col :span="3">
            <a-input
              :maxLength="30"
              allow-clear
              v-model="managementCode"
              :placeholder="$t('information.a7')"
              :title="$t('information.a7')"
              style="width: 100%"
              v-Input-NumberText
            />
          </a-col>
          <a-col :span="3">
            <a-input
              :maxLength="30"
              allow-clear
              v-model="cardNo"
              :placeholder="$t('information.a9')"
              :title="$t('information.a9')"
              style="width: 100%"
              v-pureNumber
            />
          </a-col>
          <a-col :span="3">
            <a-input
              :maxLength="30"
              allow-clear
              v-model="cardFaceNo"
              :placeholder="$t('information.a11')"
              :title="$t('information.a11')"
              style="width: 100%"
              v-Input-NumberText
            />
          </a-col>
          <a-col :span="2">
            <a-select v-model="option" style="width: 100%">
              <a-select-option :value="0">
                {{ $t("information.a14") }}
              </a-select-option>
              <a-select-option :value="1">
                {{ $t("information.a15") }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :span="3">
            <a-select v-model="cardType" style="width: 100%">
              <a-select-option :value="10">
                {{ $t("information.a17") }}
              </a-select-option>
              <a-select-option :value="0">
                {{ $t("information.a18") }}
              </a-select-option>
              <a-select-option :value="1">
                {{ $t("information.a19") }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :span="3">
            <a-select v-model="usageCondition" style="width: 100%">
              <a-select-option :value="10">
                {{ $t("information.a21") }}
              </a-select-option>
              <a-select-option :value="0">
                {{ $t("information.a22") }}
              </a-select-option>
              <a-select-option :value="1">
                {{ $t("information.a23") }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :span="3">
            <a-select v-model="status" style="width: 100%">
              <a-select-option :value="10">
                {{ $t("information.a25") }}
              </a-select-option>
              <a-select-option :value="1">
                {{ $t("information.a26") }}
              </a-select-option>
              <a-select-option :value="2">
                {{ $t("information.a27") }}
              </a-select-option>
              <a-select-option :value="3">
                {{ $t("information.a28") }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :span="1">
            <a-button type="primary" @click="inquire">
              {{ $t("information.a29") }}
            </a-button>
          </a-col>
        </a-row>
      </div>
    </div>
    <!-- 主内容区域 -->
    <div class="main" ref="main" v-table-size:information="tableSize">
      <my-tabletitle>
        {{ $t("information.a30") }}
        <template v-slot:name>
          <a-button
            type="primary"
            icon="file-text"
            size="small"
            ghost
            :disabled="list.length == 0"
            @click="csvExport"
            style="margin-right: 20px"
          >
            {{ $t("alarm.a51") }}
          </a-button>
          <a-popover placement="bottomRight">
            <template slot="content">
              <a-row style="width: 350px">
                <a-col
                  v-for="(item, index) in displayContent"
                  :key="index"
                  :span="12"
                >
                  <a-checkbox :disabled="item.disabled" v-model="item.select">
                    {{ item.title }}
                  </a-checkbox>
                </a-col>
              </a-row>
            </template>
            <template slot="title">
              <span>{{ $t("alarm.a50") }}</span>
            </template>
            <a-button type="primary" size="small">
              {{ $t("alarm.a50") }}
            </a-button>
          </a-popover>
        </template>
      </my-tabletitle>
      <a-table
        :columns="column"
        :dataSource="list"
        :scroll="size"
        :pagination="pagination"
        :rowKey="(record) => record.cardId"
      >
        <template slot="chaozuo" slot-scope="text, record">
          <div>
            <a-popover placement="left">
              <template slot="content">
                <a-button
                  type="primary"
                  class="table_button"
                  v-if="record.usageCondition == 0"
                  @click="cardDelete(record)"
                >
                  {{ $t("information.a31") }}
                </a-button>
                <a-button type="primary" @click="openCardCompile(record)">
                  {{ $t("information.a32") }}
                </a-button>
              </template>
              <a>{{ $t("information.a33") }}</a>
            </a-popover>
          </div>
        </template>
      </a-table>
    </div>
    <!-- 底栏 -->
    <div class="footer">
      <a-button
        type="primary"
        style="width: 120px"
        @click="openCardAffiliationCompile"
      >
        {{ $t("information.a34") }}
      </a-button>
      <a-button type="primary" style="width: 120px" @click="addData">
        {{ $t("information.a35") }}
      </a-button>
      <!-- 卡所属编辑 -->
      <my-cardAffiliationCompile
        :visible="cardAffiliationCompile"
        :data="compileOption"
        @shut="handleCancel"
        :siteId="siteId"
      />
      <!-- 卡添加 -->
      <my-cardDataAdd
        :visible="cardAddVisible"
        :data="compileOption"
        @shut="addCancel"
        :siteId="siteId"
      />
      <!-- 卡编辑 -->
      <my-cardCompileData
        :visible="cardCompileVisible"
        :data="compileOption"
        :cardData="cardData"
        :siteId="siteId"
        @shut="cardCompileCancel"
        @Update="Update"
      />
    </div>
  </div>
</template>

<script>
import tabletitle from "../../components/Title/tabletitle";
import headerTitle from "../../components/Title/headerTitle";
import cardAffiliationCompile from "./components/cardAffiliationCompile";
import cardDataAdd from "./components/cardDataAdd";
import global from "@/mixins/global.js";
import cardCompileData from "./components/cardCompileData";
import { deleteInfo,getInfoList, getInfoList_page } from "../../api/info";
import {
  tableSort,
  csvDownload,
  tableTitleDisplay,
  tableIndexDisplay,
} from "../../utils/utils";

export default {
  name: "information",
  mixins: [global],
  data() {
    return {
      loading: false,
      option: 0,
      cardType: 10,
      usageCondition: 10,
      status: 10,
      name: "",
      cardNo: "",
      cardFaceNo: "",
      managementCode: "",
      options: [],
      compileOption: [],
      select: [],
      list: [],
      cardData: {},
      size: { x: 1900, y: 240 },
      cardAffiliationCompile: false,
      cardAddVisible: false,
      cardCompileVisible: false,
      columns: [
        {
          id: "1",
          title: "NO",
          dataIndex: "NO",
          customRender: (text, record, index) =>
            `${
              (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
              index +
              1
            }`,
          width: 60,
          fixed: "left",
          align: "center",
        },
        {
          id: "2",
          title: this.$t("information.a9"),
          customRender: (text, record, index) => {
            const value = record.cardNo;
            if (value == null) {
              return "--";
            } else {
              return value;
            }
          },
          dataIndex: "cardNo",
          sorter: (a, b) => {
            return tableSort(a, b, "cardNo");
          },
          width: 130,
          align: "center",
        },
        {
          id: "3",
          title: this.$t("information.a11"),
          customRender: (text, record, index) => {
            const value = record.cardFaceNo;
            if (value == null) {
              return "--";
            } else {
              return value;
            }
          },
          dataIndex: "cardFaceNo",
          sorter: (a, b) => {
            return tableSort(a, b, "cardFaceNo");
          },
          width: 130,
          align: "center",
        },
        {
          id: "4",
          title: this.$t("information.a16"),
          customRender: (text, record, index) => {
            switch (record.temporaryCard) {
              case 0:
                return this.$t("information.a18");
                break;
              case 1:
                return this.$t("information.a19");
                break;
              default:
                return "--";
                break;
            }
          },
          dataIndex: "temporaryCard",
          sorter: (a, b) => {
            return tableSort(a, b, "temporaryCard");
          },
          width: 100,
          align: "center",
        },
        {
          id: "5",
          title: this.$t("information.a20"),
          customRender: (text, record, index) => {
            switch (record.usageCondition) {
              case 0:
                return this.$t("information.a22");
                break;
              case 1:
                return this.$t("information.a23");
                break;
              default:
                return "--";
                break;
            }
          },
          dataIndex: "usageCondition",
          sorter: (a, b) => {
            return tableSort(a, b, "usageCondition");
          },
          width: 100,
          align: "center",
        },
        {
          id: "6",
          title: this.$t("information.a24"),
          customRender: (text, record, index) => {
            switch (record.status) {
              case 1:
                return this.$t("information.a37");
                break;
              case 2:
                return this.$t("information.a38");
                break;
              case 3:
                return this.$t("information.a39");
                break;
              case 4:
                return this.$t("information.a40");
                break;
              case 5:
                return this.$t("information.a41");
                break;
              default:
                return "--";
                break;
            }
          },
          dataIndex: "status",
          sorter: (a, b) => {
            return tableSort(a, b, "status");
          },
          width: 90,
        },
        {
          id: "7",
          title: this.$t("information.a7"),
          customRender: (text, record, index) => {
            const value = record.managementCode;
            if (value == null) {
              return "--";
            } else {
              return value;
            }
          },
          dataIndex: "managementCode",
          sorter: (a, b) => {
            return tableSort(a, b, "managementCode");
          },
          width: 130,
        },
        {
          id: "8",
          title: this.$t("information.a5"),
          customRender: (text, record, index) => {
            const value = record.name;
            if (value == null) {
              return "--";
            } else {
              return value;
            }
          },
          dataIndex: "name",
          sorter: (a, b) => {
            return tableSort(a, b, "name");
          },
          width: 100,
        },
        {
          id: "9",
          title: this.$t("information.a42"),
          customRender: (text, record, index) => {
            const value = record.personGrName;
            if (value == null) {
              return "--";
            } else {
              return value;
            }
          },
          dataIndex: "personGrName",
          sorter: (a, b) => {
            return tableSort(a, b, "personGrName");
          },
          width: 200,
        },
        {
          id: "10",
          title: this.$t("information.a43"),
          customRender: (text, record, index) => {
            const value = record.related_building;
            if (value == null) {
              return "--";
            } else {
              return value;
            }
          },
          dataIndex: "related_building",
          sorter: (a, b) => {
            return tableSort(a, b, "related_building");
          },
          width: 200,
        },
        {
          id: "11",
          title: this.$t("information.a44"),
          customRender: (text, record, index) => {
            const value = record.activationTime;
            if (value == null) {
              return "--";
            } else {
              return value;
            }
          },
          dataIndex: "activationTime",
          sorter: (a, b) => {
            return tableSort(a, b, "activationTime");
          },
          width: 130,
        },
        {
          id: "12",
          title: this.$t("information.a45"),
          customRender: (text, record, index) => {
            const value = record.expirationTerm;
            if (value == null) {
              return "--";
            } else {
              return value;
            }
          },
          dataIndex: "expirationTerm",
          sorter: (a, b) => {
            return tableSort(a, b, "expirationTerm");
          },
          width: 100,
        },
        {
          id: "13",
          title: this.$t("information.a46"),
          customRender: (text, record, index) => {
            const value = record.presentAccessTime;
            if (value == null) {
              return "--";
            } else {
              return value;
            }
          },
          dataIndex: "presentAccessTime",
          sorter: (a, b) => {
            return tableSort(a, b, "presentAccessTime");
          },
          width: 130,
        },
        {
          id: "14",
          title: this.$t("information.a47"),
          customRender: (text, record, index) => {
            const value = record.description;
            if (value == null) {
              return "--";
            } else {
              return value;
            }
          },
          dataIndex: "description",
          sorter: (a, b) => {
            return tableSort(a, b, "description");
          },
          width: 100,
        },
        {
          id: "15",
          title: this.$t("information.a48"),
          dataIndex: "chaozuo",
          scopedSlots: { customRender: "chaozuo" },
          width: 100,
          fixed: "right",
          align: "center",
        },
      ],
      column: [],
      timeout:null,
      displayContent:
        JSON.parse(localStorage.getItem("informtion_tableTitle")) || [],
      pagination: {
        onShowSizeChange: (current, pageSize) => {
          this.pagination.defaultCurrent = current;
          this.pagination.pageSize = pageSize;
          if(this.$platform.spider==='NEW'){
            this.inquire();
          }
        },
        onChange: (current, size) => {
          this.pagination.defaultCurrent = current;
          this.pagination.pageSize = size;
          if(this.$platform.spider==='NEW'){
            this.inquire();
          }
        },
      },
    };
  },
  watch: {
    // 监听站点ID调用获取卡所属列表的方法
    "$store.getters.sitegrId": {
      handler: function (newValue, oldValue) {
        this.loading = true
        this.pagination.total = 0
        this.list = []
        if (newValue) {
          let data = { siteGrId: newValue }
          if(this.timeout !== null){
            clearTimeout(this.timeout)
          }
          this.timeout = setTimeout(()=>{
              this.$store
              .dispatch("info/getBelonging", data)
              .then((res) => {
                this.loading = false;
                let data = this.$store.getters.cardList;
                let arr = [data[0].id, data[0].children[0].id];
                this.select = arr;
                this.options = data;
              })
              .catch((err) => {
                this.loading = false;
              })
            },1000)
        }
      },
      deep: true,
      immediate: true,
    },
    // 获取卡所属列表和列表选中值
    "$store.getters.cardList": {
      handler: function (newValue, oldValue) {
        let data = newValue;
        if (this.select.length !== 0) {
          this.options = data;
          let val = this.select[0];
          let arr = [];
          for (let i = 0; i < this.options.length; i++) {
            if (this.options[i].id == val) {
              arr.push(this.options[i]);
            }
          }
          this.compileOption = arr;
        } else {
          let arr = [data[0].id, data[0].children[0].id];
          this.select = arr;
          this.options = data;
        }
      },
      deep: true,
    },
    // 卡所属选择内容截取
    select: {
      handler: function (newValue, oldValue) {
        this.pagination.total = 0;
        this.list = [];
        let val = this.select[0];
        let arr = [];
        for (let i = 0; i < this.options.length; i++) {
          if (this.options[i].id == val) {
            arr.push(this.options[i]);
          }
        }
        if (newValue.length == 1) {
          this.select.push(arr[0].children[0].id);
        }
        this.compileOption = arr;
      },
    },
    // 监听表格表头显示内容生成表格表头
    displayContent: {
      handler(newValue) {
        const columns = this.columns;
        const name = "informtion_tableTitle";
        const data = tableIndexDisplay(name, columns, newValue);
        this.column = data;
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    // 站点Id操作处理
    siteId() {
      let value = this.select[0];
      let arr = [];
      if (value) {
        arr = value.split("_");
      }
      let siteId = arr[0];
      return siteId;
    },
  },
  mounted() {
    // 表格表头
    const name = "informtion_tableTitle";
    const columns = this.columns;
    const data = tableTitleDisplay(name, columns);
    if (data.columns == undefined) {
      this.displayContent = data.displayContent;
    } else {
      this.column = data.columns;
      this.displayContent = data.displayContent;
    }
  },
  methods: {
    // 查询方法
    inquire() {
      this.loading = true;
      let data = {
        ex_ninsyou: this.option,
        cardType: this.cardType,
        usageCondition: this.usageCondition,
        status: this.status,
        personName: this.name,
        cardNo: this.cardNo,
        cardFaceNo: this.cardFaceNo,
        managementCode: this.managementCode,
        siteId: this.siteId,
        credentialGrId: this.select[this.select.length - 1],
        pageNum: this.pagination.defaultCurrent,
        pageSize: this.pagination.pageSize,
      };
      console.log(this.$platform.spider);
      if(this.$platform.spider==='NEW'){
        getInfoList_page(data)
        .then((res) => {
          let {
            data: { list, total },
          } = res;
          this.list = list;
          this.pagination.total = total;
          this.loading = false;
          console.log(res);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
      }else{
        getInfoList(data)
        .then((res) => {
          this.list = res.data;
          this.loading = false;
          console.log(res);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
      }
      
    },
    openCardAffiliationCompile() {
      this.cardAffiliationCompile = true;
    },
    // 卡所属编辑弹窗关闭方法
    handleCancel(e) {
      this.cardAffiliationCompile = e;
    },
    // 删除按钮
    cardDelete(value) {
      this.$confirm({
        title: this.$t("information.a49"),
        centered: true,
        onOk: () => {
          this.loading = true;
          let data = {
            cardIds: [value.cardId],
            siteId: this.siteId,
          };
          deleteInfo(data)
            .then((res) => {
              this.loading = false;
              if (res.errorCode == "00") {
                this.$message.success(res.msg);
                let list = this.list;
                let result = list.findIndex(
                  (row) => row.cardId === value.cardId
                );
                list.splice(result, 1);
              } else if (res.errorCode == "02") {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              console.log(err);
              this.loading = false;
            });
        },
      });
    },
    // 打开卡编辑弹窗
    openCardCompile(value) {
      this.cardData = value;
      this.cardCompileVisible = true;
    },
    // 打开添加
    addData() {
      this.cardAddVisible = true;
    },
    // 关闭添加弹窗
    addCancel(e) {
      this.cardAddVisible = e;
    },
    // 关闭编辑弹窗
    cardCompileCancel(e) {
      this.cardCompileVisible = e;
    },
    // 编辑成功后新数据在表格中更改
    Update(value) {
      const list = this.list;
      const result = list.findIndex((row) => row.cardId === value.cardId);
      this.list[result] = value;
    },
    // csv导出
    csvExport() {
      const options = this.options;
      const select = this.select[0];
      let name;
      for (let i = 0; i < options.length; i++) {
        if (select == options[i].id) {
          name = options[i].name;
        }
      }
      const fileName = `${name}_${this.$t("information.a2")}`;
      const data = this.list;
      const columns = this.column;
      csvDownload(data, columns, fileName);
    },
  },
  components: {
    "my-tabletitle": tabletitle,
    "my-headertitle": headerTitle,
    "my-cardAffiliationCompile": cardAffiliationCompile,
    "my-cardDataAdd": cardDataAdd,
    "my-cardCompileData": cardCompileData,
  },
};
</script>

<style scoped>
#information {
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 10px #bdbcbc;
  overflow: hidden;
}
.header {
  padding: 10px 20px;
}
.main {
  height: calc(100% - 178px);
  padding: 0px 20px;
  overflow: hidden;
}
.footer {
  display: flex;
  justify-content: flex-end;
  height: 52px;
  padding: 10px 20px;
  box-shadow: 0px 0px 10px #bdbcbc;
}
.footer button {
  margin-left: 15px;
}
.table_button {
  margin-right: 15px;
}
.queryBar {
  min-width: 1250px;
  padding: 10px;
  min-width: 850px;
  background-color: aliceblue;
  border-bottom: 1px solid rgba(12, 12, 12, 0.1);
}
</style>

